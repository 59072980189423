<template>
  <div :class="{ 'wrapper-mobile': isMobile, 'wrapper-pc': !isMobile }">
    <div class="boxs">
      <t-dialog :closeBtn="false" :footer="false" width="500px" header v-model:visible="usestore.visible">
        <div class="dialog">
          <p style="font-size: 35px; font-weight: 500;">WB商家服务中心</p>
          <!-- 获取验证码 -->
          <div class="dialog_one" v-if="toggle == false">
            <p>创建个人资料</p>
            <t-form ref="form" :data="formcor" :rules="rules">
              <t-form-item name="">
                <t-input placeholder="请输入公司名称" v-model="formcor.company_name" :disabled="disabledif"></t-input>
              </t-form-item>
              <t-form-item name="">
                <t-input placeholder="请输入统一社会信用代码" v-model="formcor.company_code" :disabled="disabledif"></t-input>
              </t-form-item>
              <t-form-item name="">
                <t-input type="password" placeholder="设置密码" v-model="formcor.loginpass">
                  <template #prefix-icon>
                    <lock-on-icon />
                  </template>
                </t-input>
              </t-form-item>
              <t-form-item name="">
                <t-input type="password" placeholder="确认设置密码" v-model="formcor.quepass">
                  <template #prefix-icon>
                    <lock-on-icon />
                  </template>
                </t-input>
              </t-form-item>
              <div style="display: flex;">
                <t-button v-if="!corshow" @click="verify" class="logbtn">验证信息</t-button>
              </div>
              <t-form-item v-if="corshow" name="">
                <t-input placeholder="请输入手机号" v-model="mobile" maxlength="11"></t-input>
              </t-form-item>
              <t-form-item v-if="corshow" name="">
                <t-input placeholder="请输入验证码" v-model="code"></t-input>
              </t-form-item>
              <t-form-item v-if="corshow" name="">
                <t-input placeholder="请输入邀请码" v-model="pregcode" :disabled="pregcodeif"></t-input>
              </t-form-item>
              <div style="display: flex;width: 100%;">
                <t-button v-if="corshow" @click="GetCode(mobile, 'register')" :disabled="countingDown" class="logbtn">
                  {{
                    buttonText
                  }}</t-button>
              </div>
              <t-button v-if="corshow" @click="Validate" class="logbtn1">注册</t-button>
            </t-form>
            <div class="loginst" @click="toggle = !toggle">
              {{ toggle ? '前往注册' : '手机号密码登录' }}
            </div>
            <t-checkbox v-model="checkbox">
              <sapn style="color: #424542;">我同意个人数据的处理和门户使用规则有关数据处理的更多信息</sapn>
            </t-checkbox>
          </div>
          <!-- 登录 -->
          <div class="dialog_one" v-else>
            <t-form ref="form" :data="forminfo" :rules="rules">
              <t-form-item name="code">
                <t-input v-model="forminfo.phone" placeholder="请输入手机号" :maxlength="11"></t-input>
              </t-form-item>
              <t-form-item name="code" v-if="!loginshow">
                <t-input type="password" v-model="forminfo.password" placeholder="请输入密码">
                  <template #prefix-icon>
                    <lock-on-icon />
                  </template>
                </t-input>
              </t-form-item>
              <t-form-item name="" v-if="loginshow">
                <t-input v-model="forminfo.code" placeholder="请输入验证码"></t-input>
              </t-form-item>
              <div style="display: flex;width: 100%;" v-if="loginshow">
                <t-button @click="GetCode(forminfo.phone, 'login')" :disabled="countingDown" class="logbtn"> {{
                  buttonText
                }}</t-button>
              </div>
              <t-button @click="loginweb" class="logbtn1">登录</t-button>
            </t-form>
            <!-- <t-button @click="GetCodes" :disabled="disabled" class="logbtn">重新获取验证码</t-button> -->
            <div style="display: flex; gap: 10px;">
              <div class="loginst" @click="toggle = !toggle">
                {{ toggle ? '前往注册' : '手机号密码登录' }}
              </div>
              <div class="loginst" @click="loginshow = !loginshow">
                {{ loginshow ? '手机号密码登录' : '验证码登录 ' }}
              </div>
              <div class="loginst" @click="resupass">
                重置密码
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </t-dialog>
    </div>
  </div>
</template>

<script setup>
import { verificationCode, LogIn, codeLogIn, Reqloginregister, Reqpostcompany_match, webConf } from '../api/api'
import { ref, reactive, onMounted } from 'vue'
import { MessagePlugin } from 'tdesign-vue-next'
import { useUserStore } from '../store/index'

const usestore = useUserStore()
const checkbox = ref(true)
const toggle = ref(true)
const loginshow = ref(false)//验证码登录切换
const corshow = ref(false)//公司验证确认
const supplierid = ref('')//供应商ID
const disabledif = ref(false)//是否可以填写
const pregcodeif = ref(false) //邀请码是否可以填写
/*  */
const mobile = ref('')//手机号
const code = ref('')//验证码
const pregcode = ref('')//上级邀请码
/*  */
const count = ref()
const buttonText = ref('发送验证码');
const countingDown = ref(false)
/*  */
const formcor = reactive({
  company_name: '',//公司名称
  company_code: '',//统一社会信用代码
  loginpass: '',//密码
  quepass: '',//确认密码
})
const forminfo = reactive({
  phone: '',
  password: '',
  code: ''
})
const rules = {
  mobile: [
    {
      required: true,
      message: '输入完整的手机号',
      type: 'error',
      trigger: 'blur',
    },
    { min: 11, message: '输入完整的手机号', type: 'error', trigger: 'blur' },
    { max: 11, message: '输入完整的手机号', type: 'error', trigger: 'blur' },
  ],
}
// 'login'://登录"register': //注册'modify’://修改手机号
const isMobile = ref(false)
// 获取验证码
async function GetCode(phone, codetype) {
  if (!countingDown.value) {
    const res = await verificationCode({
      mobile: phone,
      captcha: '123456',
      type: codetype,
      uuid: '123456'
    })
    if (res.code == 1) {
      MessagePlugin.success({ content: res.msg })
      count.value = 60
      countingDown.value = true
      const timer = setInterval(() => {
        count.value--;
        buttonText.value = `${count.value} 秒后重新发送`;
        if (count.value === 0) {
          clearInterval(timer);
          countingDown.value = false;
          buttonText.value = '发送验证码';
        }
      }, 1000);
    } else {
      MessagePlugin.error({ content: res.msg })
    }
  }
}
// 注册
const Validate = async () => {
  if (code.value.length >= 6) {
    const res = await Reqloginregister({
      company_name: formcor.company_name,
      company_code: formcor.company_code,
      loginpass: formcor.loginpass,
      quepass: formcor.quepass,
      regcode: pregcode.value,
      mobile: mobile.value,
      code: code.value,
      supplier_id: supplierid.value
    })
    if (res.code == 1) {
      MessagePlugin.success(res.msg + '  请尽快完善个人信息')
      toggle.value = true
    } else {
      MessagePlugin.error(res.msg)
    }
  } else {
    MessagePlugin.error('请输入手机号/验证码')
  }
}
//登录事件
const loginweb = async () => {
  if (loginshow.value) {
    const res = await codeLogIn({
      mobile: forminfo.phone,
      code: forminfo.code
    })
    loginres(res)
  } else {
    const res = await LogIn({
      mobile: forminfo.phone,
      loginpass: forminfo.password
    })
    loginres(res)
  }
}
//登录事件获取res
const loginres = (res) => {
  if (res.code == 1) {
    usestore.visible = false
    usestore.data.access_token = res.data.token.access_token
    localStorage.setItem('token', res.data.token.access_token)
    window.location.reload();
    MessagePlugin.success('登陆成功')
  } else {
    MessagePlugin.error(res.msg)
  }
}
//密码修改
const resupass = () => {
  webConf().then((res) => {
    MessagePlugin.error(res.data.forget_pass_tips)
  })
}
//验证信息
const verify = async () => {
  const res = await Reqpostcompany_match(formcor)
  if (res.code == 1) {
    MessagePlugin.success(res.msg)
    disabledif.value = true
    setTimeout(() => {
      corshow.value = !corshow.value
    }, 300)
  } else {
    MessagePlugin.error(res.msg)
  }
}
// http://localhost:8080/?owner_id=2
onMounted(() => {
  const queryurl = new URLSearchParams(window.location.search)
  if (queryurl.get('owner_id')) {
    supplierid.value = queryurl.get('owner_id')
  }
  if (queryurl.get('regcode')) {
    pregcode.value = queryurl.get('regcode')
    pregcodeif.value = true
  }
  checkMobile();
  window.addEventListener('resize', checkMobile);
});
function checkMobile() {
  isMobile.value = window.innerWidth <= 1024;
}
</script>
<style lang="scss" scoped>
.wrapper-pc {
  .box {
    display: none;
  }

  .dialog {
    display: flex;
    align-items: center;
    flex-direction: column;

    .dialog_one {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    p {
      font-size: 24px;
      line-height: 32px;
      color: #000;
    }


  }



}

.wrapper-mobile {
  .boxs {
    display: block;
  }

  .dialog {
    display: flex;
    align-items: center;
    flex-direction: column;

    .dialog_one {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    p {
      font-size: 24px;
      line-height: 32px;
      color: #000;
    }

    .logbtn {
      width: 100%;
      margin: 30px 0;
      background: #7001fd;
      border: 0;
      font-size: 24px;
      line-height: 32px;
      border-radius: 10px;
      padding: 28px 35px;
      box-sizing: border-box;
    }
  }

  ::v-deep(.t-checkbox.t-is-checked .t-checkbox__input) {
    background: #7001fd !important;
    border: #7001fd solid 1px;
  }

  ::v-deep(.t-form__controls) {
    margin-left: 0px !important;
  }

  ::v-deep(.t-input) {
    width: 100% !important;
    height: 50px !important;
    border-radius: 15px;
  }

  ::v-deep(.t-form__label) {
    display: none;
  }
}

:deep(.t-form__item) {
  margin-bottom: 12px;
}

::v-deep(.t-checkbox.t-is-checked .t-checkbox__input) {
  background: #7001fd !important;
  border: #7001fd solid 1px;
}

.logbtn {
  width: 40%;
  margin: 10px 0;
  background: #7001fd;
  border: 0;
  font-size: 20px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.logbtn1 {
  width: 100%;
  margin: 10px 0;
  background: #7001fd;
  border: 0;
  font-size: 24px;
  border-radius: 10px;
  padding: 25px 35px;
  box-sizing: border-box;
}

::v-deep(.t-form__controls) {
  margin-left: 0px !important;
}

::v-deep(.t-input) {
  width: 380px !important;
  height: 45px !important;
  border-radius: 15px;
}

::v-deep(.t-form__label) {
  display: none;
}

.loginst {
  cursor: pointer;
  font-size: 20px;
  color: #7001fd;
  margin-bottom: 10px;
  text-decoration: underline;
}
</style>