<template>
  <div :class="{ 'footer-mobile': isMobile, 'footer-pc': !isMobile }">
    <div class="footer_top">
      <div v-for="(item, index) in list" :key="index">
        <div>
          <p class="footer_top_p">{{ item.cate_name }}</p>
          <div v-for="(ele, indexOf) in item.footer_list" :key="indexOf">
            <p class="footer_top_p1" @click="footck(ele)">{{ ele.title }}</p>
            <!-- <img v-if="ele.image" class="footer_top_img" :src="ele.image" alt=""> -->
          </div>
        </div>
      </div>
    </div>
    <div class="footer_top_mobile">
      <t-space direction="vertical">
        <t-collapse :expand-icon-placement="direction" :expand-on-row-click="!onlyIcon">
          <t-collapse-panel v-for="(item, index) in list" :key="index" :value="index" :header="item.cate_name">
            <p v-for="(ele, indexOf) in item.footer_list" :key="indexOf" @click="footck(ele)">{{ ele.title }}</p>
          </t-collapse-panel>
        </t-collapse>
      </t-space>
    </div>
    <div class="footer_bom">
      <p>{{ textconf.web_site_copyright }}{{ textconf.web_site_icp }}</p>
    </div>
    <t-dialog :footer="false" width="800px" header preventScrollThrough closeOnOverlayClick closeOnEscKeydown
      :closeBtn="false" v-model:visible="visible">
      <div class="dialogshow">
        <img :src="showimg" alt="">
      </div>
    </t-dialog>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import { footerList, webConf } from '../api/api';
const visible = ref(false)//遮盖层
const showimg = ref()
const router = useRouter()
const direction = ref('right');
const onlyIcon = ref(false);
const options = [
  {
    label: '公司',
    name: [
      '关于该项目',
      '测试',
      '对于卖家',
      '市场经理',
      '活动日历',
      '使用门户的规则'
    ],
  },
  {
    label: '我们的计划',
    name: [
      '世界银行合作伙伴',
      'WB流',
      '华宝数码',
      '世界银行大师',
      '世界银行印象',
      '世界银行特许经营权'
    ],
  },
  {
    label: '合作',
    name: [
      '课程认证',
      '与世界银行启动一个项目',
    ],
  },
  {
    label: '我们在社交网络中',
    name: [
      '电报',
      'YouTube',
    ],
  },
  {
    label: '联系我们',
    name: [
      '反馈',
      '支持聊天',
    ],
  },
];
const list = ref([])
const textconf = ref({})
const isMobile = ref(false);

onMounted(() => {
  footerList().then(res => {
    list.value = res.data
  })
  webConf().then(res => {
    textconf.value = res.data
  })
  checkMobile();
  window.addEventListener('resize', checkMobile);
});

function checkMobile() {
  isMobile.value = window.innerWidth <= 1024;
}

const footck = (val: any) => {
  if (val.image) {
    showimg.value = val.image
    visible.value = true
  } else {
    window.open(val.desc, "_blank")
  }
}
</script>

<style lang="scss">
.footer-pc {
  max-width: 1392px;
  min-width: 1024px;
  margin: 0 auto;

  .footer_top_mobile {
    display: none;
  }

  .footer_top {
    display: flex;
    justify-content: space-between;

    .footer_top_p {
      color: white;
      font-size: 28px;
      cursor: pointer;
    }

    .footer_top_p1 {
      color: #ffffff;
      cursor: pointer;
      font-size: 20px;
    }

    .footer_top_img {
      width: 200px;
      height: 200px;
    }
  }

  .footer_bom {
    margin: 20px 0px;

    p {
      color: #dddae0;
      font-size: 19px;
    }
  }
}

.footer-mobile {
  padding: 0 20px;
  box-sizing: border-box;

  .footer_top {
    display: none;
  }

  .footer_top_mobile {
    .t-space {
      display: block;
    }

    .t-collapse {
      background-color: rgba(255, 255, 255, 0);
      color: #ffffff;
      border: 0;
    }

    .t-collapse-panel__header {
      border-bottom: 1px solid #4a4352;
    }

    .t-collapse-panel__body {
      background-color: rgba(255, 255, 255, 0);
      border: 0;
    }

    .t-collapse-panel__content {
      color: #ccc;
      border-bottom: 1px solid #4a4352;
    }
  }

  .footer_bom {
    font-size: 3.75vw;
    line-height: 5vw;
    padding-top: 10vw;
    padding-bottom: 10vw;
  }
}

.dialogshow {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>