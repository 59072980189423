<template>
    <div :class="{ 'wrapper-mobile': isMobile, 'wrapper-pc': !isMobile }">
        <t-layout class="layout">
            <t-aside>
                <t-menu default-value="item1" theme="dark" @change="changeHandler" style="margin-right: 40px" router>
                    <!-- <div class="min-info">
                        <span>公司名称: {{ infolist.company_name }}</span>
                        <span>手机号: {{ infolist.mobile }}</span>
                    </div> -->
                    <t-menu-item v-for="(item, index) in option" :key="index" :value="item.value" :to="item.path">{{
                        item.content
                    }}</t-menu-item>
                </t-menu>
            </t-aside>

            <div class="content-box">
                <RouterView></RouterView>
            </div>

        </t-layout>
    </div>
</template>

<script setup>
import { getmaterial } from '@/api/api'
import { ref, onMounted } from 'vue'
const infolist = ref({})
const option = ref([
    { content: '个人信息', value: '1', path: '/mine/mineinfo' },
    { content: '浏览记录', value: '2', path: '/mine/minevideo' },
    // { content: '我的培训', value: '2' },
    // { content: '我的订单', value: '3' },
    //{ content: '推荐计划', value: '3' },
    { content: '帮助', value: '3', path: '/mine/mineassist' },
])
const isMobile = ref(false);
onMounted(async () => {
    const res = await getmaterial()
    infolist.value = res.data.data
    console.log(infolist.value, '111111');

    checkMobile();
    window.addEventListener('resize', checkMobile);
});
function checkMobile() {
    isMobile.value = window.innerWidth <= 1024;
}
</script>
<style lang="scss" scoped>
.min-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px 0 16px;
    margin: 4px 0 0;
    font-size: 18px;
    color: #ffffff;
}

.wrapper-pc {
    .t-layout__sider {
        height: 60vh;
    }

    .content-box {
        // background: #ffffff;
        width: 100%;
        height: 100%;
        margin: 0 10px;
        border-radius: 10px;
        padding: 10px 0;
        box-sizing: border-box;
    }
}

.wrapper-mobile {
    .t-layout__sider {
        display: block;
    }

    .content-box {
        background-color: rgba(255, 255, 255, 0);
    }
}

@media screen and (max-width:750px) {
    .content-box {
        width: 100%;
    }

    .t-layout__sider {
        width: 90%;
        margin: 0 auto !important;
        margin-bottom: 20px !important;
    }

    .t-default-menu.t-menu--dark {
        width: 100% !important;
        margin-right: 0 !important;
    }
}
</style>