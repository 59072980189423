import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-wrapper" }

import headTop from "@/components/headTop.vue"
import footBot from "@/components/footBot.vue"
import diaLog from "@/components/diaLog.vue"
import { ref, onMounted } from 'vue';
import { webConf } from "@/api/api";
import { useUserStore } from './store/index'

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const getinfo = async () => {
    const res = await webConf()
    document.title = res.data.web_name

}
getinfo()
const isMobile = ref(false);
const usestore = useUserStore()
onMounted(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
    if (!localStorage.getItem('token')) {
        usestore.visible = true
    }
});

function checkMobile() {
    isMobile.value = window.innerWidth <= 1024;
}

return (_ctx: any,_cache: any) => {
  const _component_t_header = _resolveComponent("t-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_t_content = _resolveComponent("t-content")!
  const _component_t_footer = _resolveComponent("t-footer")!
  const _component_t_layout = _resolveComponent("t-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_t_layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_t_header, null, {
          default: _withCtx(() => [
            _createVNode(headTop)
          ]),
          _: 1
        }),
        _createVNode(_component_t_content, null, {
          default: _withCtx(() => [
            _createVNode(diaLog),
            _createVNode(_component_router_view)
          ]),
          _: 1
        }),
        _createVNode(_component_t_footer, null, {
          default: _withCtx(() => [
            _createVNode(footBot)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})